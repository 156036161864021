import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import moment from 'moment';

const TodayAttendanceCount = () => {
    const [todayAttendanceCount, setTodayAttendanceCount] = useState(0);

    useEffect(() => {
        const fetchTodayAttendanceCount = () => {
            const db = getDatabase();
            const today = moment().format('YYYY-MM-DD');
            const attendanceRef = ref(db, `attendance/${today}`);

            onValue(attendanceRef, (snapshot) => {
                const attendanceData = snapshot.val();
                setTodayAttendanceCount(attendanceData ? Object.keys(attendanceData).length : 0);
            });
        };

        fetchTodayAttendanceCount();
    }, []);

    return (
        <div>
            <h2 style={{ textAlign: 'left', marginBottom: '16px' }}>
                今日打卡人數: <span style={{ color: '#1890ff' }}>{todayAttendanceCount}</span>
            </h2>
        </div>
    );
};

export default TodayAttendanceCount;
