// src/hooks/useAuth.js
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../utils/firebase';

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // 添加加载状态

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            // console.log('onAuthStateChanged triggered');
            // console.log('Current user from Firebase:', currentUser); 
            setUser(currentUser);
            setLoading(false); // 状态更新完成后设置为 false
        });

        return () => unsubscribe();
    }, []);

    return { user, loading }; // 返回加载状态
};
