import React, { useState } from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import Captcha from '../components/Captcha';
import Logo from '../images/Logo.png'; // 引入图片

const LoginForm = () => {
    const [captchaInput, setCaptchaInput] = useState('');
    const [generatedCaptcha, setGeneratedCaptcha] = useState('');
    const navigate = useNavigate();

    const onFinish = (values) => {
        const trimmedInput = captchaInput.trim();

        if (generatedCaptcha.toLowerCase() !== trimmedInput.toLowerCase()) {
            console.log('Captcha validation failed');
            alert('驗證碼錯誤');
        } else {
            // 使用 Firebase Authentication 登入
            signInWithEmailAndPassword(auth, values.username, values.password)
                .then((userCredential) => {
                    // console.log('Success:', userCredential);
                    navigate('/PersonnelRecord'); // 登入成功後導航到 /PersonnelRecord
                })
                .catch((error) => {
                    console.error('Error logging in:', error);
                    alert('登入失敗，請檢查用戶名和密碼');
                });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCaptchaChange = (e) => {
        setCaptchaInput(e.target.value);
    };

    return (
        <Row
            justify="center"
            align="middle"
            style={{
                height: '100vh',
                backgroundColor: '#f0f2f5',
                flexDirection: 'column', // 垂直排列图片和表单
            }}
        >
            <Col>
                <img
                    src={Logo}
                    alt="Logo"
                    style={{
                        width: '400px', // 调整图片的宽度
                        maxWidth: '80vw', // 确保图片在较小屏幕上不会超出视窗宽度
                        height: 'auto', // 保持图片的宽高比自动调整
                    }}
                />
            </Col>
            
            <Col style={{ marginTop: '20px' }}> {/* 调整 marginTop 值来控制图片与表单之间的距离 */}
                <Card
                    title="登入"
                    bordered={false}
                    style={{
                        width: 400,
                        textAlign: 'center',
                        border: '1px solid black', // 添加黑色外框線
                    }}
                >
                    <Form
                        name="login_form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Username"
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item label="驗證碼">
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Captcha onCaptchaChange={setGeneratedCaptcha} />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Input
                                        placeholder="Enter Captcha"
                                        value={captchaInput}
                                        onChange={handleCaptchaChange}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default LoginForm;
