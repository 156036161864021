// import React, { useState } from 'react';
// import { auth, signInWithEmailAndPassword } from '../utils/firebase';
// import { db, collection, getDocs, setDoc, doc } from '../utils/firebase';
// import { Form, Input, Button, Upload, message, Layout, Row, Modal } from 'antd';
// import { UploadOutlined, DownloadOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
// import HeaderComponent from '../components/HeaderComponent';

// const { Content } = Layout;

// function Setting() {
//     const buttonStyle = { width: '200px' };
//     const [loading, setLoading] = useState(false);
//     const [isAuthenticated, setIsAuthenticated] = useState(false);
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [selectedFile, setSelectedFile] = useState(null); // 定义 selectedFile 状态

//     const handleLogin = async () => {
//         try {
//             const userCredential = await signInWithEmailAndPassword(auth, email, password);
//             const user = userCredential.user;

//             if (user.uid === 'qDOViRuj9EaIIWCdihtkElSqLPg2') {
//                 setIsAuthenticated(true);
//                 message.success('登錄成功！可以使用所有功能');
//             } else {
//                 message.error('無權限訪問此頁面');
//             }
//         } catch (error) {
//             message.error('登錄失敗，請檢查帳號或密碼');
//             console.error('Login error:', error);
//         }
//     };

//     const handleExport = async () => {
//         const studentsCol = collection(db, 'students');
//         const studentSnapshot = await getDocs(studentsCol);

//         const studentList = {};
//         studentSnapshot.docs.forEach(doc => {
//             studentList[doc.id] = doc.data();
//         });

//         const jsonContent = JSON.stringify({ students: studentList }, null, 2);
//         const blob = new Blob([jsonContent], { type: 'application/json' });
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = 'students.json';
//         document.body.appendChild(a);
//         a.click();
//         a.remove();
//     };

//     const handleFileChange = ({ file }) => {
//         setSelectedFile(file);  // 保存选择的文件

//         Modal.confirm({
//             title: '確認上傳',
//             content: `您確定要上傳 ${file.name} 文件嗎？`,
//             onOk: () => handleImport(file),
//             onCancel: () => setSelectedFile(null), // 在取消时清除文件
//         });
//     };

//     const handleImport = async (file) => {
//         console.log('Import started for file:', file.name); // 输出导入开始信息

//         setLoading(true);
//         const hide = message.loading('匯入中，請稍候...', 0);

//         const reader = new FileReader();
//         reader.onload = async (e) => {
//             try {
//                 console.log('File read successfully'); // 检查文件是否被成功读取

//                 const jsonContent = JSON.parse(e.target.result);
//                 const studentsTestData = jsonContent['students'];

//                 if (!studentsTestData || typeof studentsTestData !== 'object') {
//                     throw new Error('JSON 檔案格式不正確或沒有 "students" 鍵');
//                 }

//                 console.log('Starting Firestore upload'); // 检查是否开始上传到 Firestore

//                 for (const [id, studentData] of Object.entries(studentsTestData)) {
//                     const docRef = doc(db, 'students', id);
//                     await setDoc(docRef, studentData);
//                 }

//                 message.success('資料已成功匯入！');
//             } catch (error) {
//                 message.error('匯入失敗，請確保文件格式正確。');
//                 console.error('Error importing data: ', error);
//             } finally {
//                 hide();
//                 setLoading(false);
//                 setSelectedFile(null); // 在上传完成后清除文件
//             }
//         };
//         reader.readAsText(file.originFileObj || file); // 读取文件内容
//     };
    
//     return (
//         <Layout>
//             <HeaderComponent />
//             <Content style={{ padding: '20px', paddingTop: '50px', position: 'relative' }}>
//                 {!isAuthenticated && (
//                     <div style={{
//                         position: 'absolute',
//                         top: 0,
//                         left: 0,
//                         width: '100%',
//                         height: '100%',
//                         backgroundColor: 'rgba(255, 255, 255, 0.8)',
//                         zIndex: 1000,
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center'
//                     }}>
//                         <div style={{
//                             padding: '20px',
//                             backgroundColor: 'rgba(255, 255, 255, 0.9)',
//                             borderRadius: '8px',
//                             boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)'
//                         }}>
//                             <Form onFinish={handleLogin} style={{ textAlign: 'center' }}>
//                                 <Form.Item>
//                                     <Input
//                                         prefix={<UserOutlined />}
//                                         type="email"
//                                         placeholder="電子郵件"
//                                         value={email}
//                                         onChange={(e) => setEmail(e.target.value)}
//                                         autoComplete="off" // 禁用自动填充
//                                     />
//                                 </Form.Item>
//                                 <Form.Item>
//                                     <Input.Password
//                                         prefix={<LockOutlined />}
//                                         placeholder="密碼"
//                                         value={password}
//                                         onChange={(e) => setPassword(e.target.value)}
//                                         autoComplete="new-password" // 阻止自动填充密码
//                                     />
//                                 </Form.Item>
//                                 <Form.Item>
//                                     <Button type="primary" htmlType="submit" style={buttonStyle}>
//                                         登錄
//                                     </Button>
//                                 </Form.Item>
//                             </Form>
//                         </div>
//                     </div>
//                 )}
//                 {loading && (
//                     <div style={{
//                         position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
//                         backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 1000, display: 'flex',
//                         justifyContent: 'center', alignItems: 'center'
//                     }}>
//                         <div>處理中，請稍候...</div>
//                     </div>
//                 )}
//                 <Row gutter={24} style={{ marginBottom: '20px', textAlign: 'left' }}>
//                     <Button type="primary" icon={<DownloadOutlined />} onClick={handleExport} size="middle" style={buttonStyle} >
//                         下載人員備份資料
//                     </Button>
//                 </Row>
//                 <Row gutter={24} style={{ marginBottom: '20px', textAlign: 'left' }}>
//                     <Upload
//                         beforeUpload={() => false} // 阻止自动上传
//                         onChange={handleFileChange} // 直接触发文件选择后的处理逻辑
//                         showUploadList={false}
//                         accept=".json"
//                     >
//                         <Button type="primary" icon={<UploadOutlined />} size="middle" style={buttonStyle} disabled={loading}>
//                             選擇備份人員資料
//                         </Button>
//                     </Upload>
//                 </Row>
//             </Content>
//         </Layout>
//     );
// }

// export default Setting;












import React, { useState, useEffect } from 'react';
import { auth, signInWithEmailAndPassword } from '../utils/firebase';
import { db, collection, getDocs, setDoc, doc } from '../utils/firebase';
import { Form, Input, Button, Upload, message, Layout, Row, Col, Modal } from 'antd';
import { UploadOutlined, DownloadOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import HeaderComponent from '../components/HeaderComponent';
import { getDatabase, ref, get } from 'firebase/database';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const { Content } = Layout;

function Setting() {
    const buttonStyle = { width: '200px' };
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [selectedFile, setSelectedFile] = useState(null); // 定义 selectedFile 状态
    const [dataUsage, setDataUsage] = useState([]);

    const handleLogin = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (user.uid === 'qDOViRuj9EaIIWCdihtkElSqLPg2') {
                setIsAuthenticated(true);
                message.success('登錄成功！可以使用所有功能');
            } else {
                message.error('無權限訪問此頁面');
            }
        } catch (error) {
            message.error('登錄失敗，請檢查帳號或密碼');
            console.error('Login error:', error);
        }
    };

    const handleExport = async () => {
        const studentsCol = collection(db, 'students');
        const studentSnapshot = await getDocs(studentsCol);

        const studentList = {};
        studentSnapshot.docs.forEach(doc => {
            studentList[doc.id] = doc.data();
        });

        const jsonContent = JSON.stringify({ students: studentList }, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'students.json';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const handleFileChange = ({ file }) => {
        setSelectedFile(file);  // 保存选择的文件

        Modal.confirm({
            title: '確認上傳',
            content: `您確定要上傳 ${file.name} 文件嗎？`,
            onOk: () => handleImport(file),
            onCancel: () => setSelectedFile(null), // 在取消时清除文件
        });
    };

    const handleImport = async (file) => {
        console.log('Import started for file:', file.name); // 输出导入开始信息

        setLoading(true);
        const hide = message.loading('匯入中，請稍候...', 0);

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                console.log('File read successfully'); // 检查文件是否被成功读取

                const jsonContent = JSON.parse(e.target.result);
                const studentsTestData = jsonContent['students'];

                if (!studentsTestData || typeof studentsTestData !== 'object') {
                    throw new Error('JSON 檔案格式不正確或沒有 "students" 鍵');
                }

                console.log('Starting Firestore upload'); // 检查是否开始上传到 Firestore

                for (const [id, studentData] of Object.entries(studentsTestData)) {
                    const docRef = doc(db, 'students', id);
                    await setDoc(docRef, studentData);
                }

                message.success('資料已成功匯入！');
            } catch (error) {
                message.error('匯入失敗，請確保文件格式正確。');
                console.error('Error importing data: ', error);
            } finally {
                hide();
                setLoading(false);
                setSelectedFile(null); // 在上传完成后清除文件
            }
        };
        reader.readAsText(file.originFileObj || file); // 读取文件内容
    };
    
    const fetchDatabaseUsage = async () => {
        const db = getDatabase();
        const dbRef = ref(db);
    
        try {
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
    
                // 計算資料大小
                const size = JSON.stringify(data).length + 5350; // 計算資料字元長度，近似資料大小

                // 更新狀態，這裡我們假設有一個限額 1GB 的 Firebase 資料庫
                // const maxSize = 1 * 1024 * 1024 * 1024; // 1 GB
                // const usedSizeMB = size / (1024 * 1024) ; // 換算成 MB
                // setDataUsage([
                //     { name: "Used", size: usedSizeMB },
                //     { name: "Remaining", size: (maxSize - size) / (1024 * 1024) },
                // ]);

                const maxSize = 1 * 1024 ; // 1 GB
                const usedSizeMB = size / (1024 * 1024) ; // 換算成 MB
                setDataUsage([
                    { name: "Used", size: usedSizeMB },
                    { name: "maxSize", size: maxSize},
                ]);

                // console.log('Data Usage:', [
                //     { name: "Used", size: usedSizeMB },
                //     { name: "Remaining", size: (maxSize - size) / (1024 * 1024) }
                // ]);
            } else {
                console.log("No data found in the database.");
            }
        } catch (error) {
            // console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchDatabaseUsage(); // 在組件加載時執行
    }, []);

    return (
        <Layout>
            <HeaderComponent />
            <Content style={{ padding: '20px', paddingTop: '50px', position: 'relative' }}>
                {!isAuthenticated && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            padding: '20px',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
                            }}
                            className="fixed-container"
                        >
                            <Form onFinish={handleLogin} style={{ textAlign: 'center' }} >
                                <Form.Item>
                                    <Input
                                        prefix={<UserOutlined />}
                                        type="email"
                                        placeholder="電子郵件"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="off" // 禁用自动填充
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        placeholder="密碼"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="new-password" // 阻止自动填充密码
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={buttonStyle}>
                                        登錄
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                )}
                {loading && (
                    <div style={{
                        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 1000, display: 'flex',
                        justifyContent: 'center', alignItems: 'center'
                    }}>
                        <div>處理中，請稍候...</div>
                    </div>
                )}
                <Row>
                    <h3>備份資料</h3>
                </Row>
                <Row gutter={24} style={{ marginBottom: '20px', textAlign: 'left' }}>
                    <Button type="primary" icon={<DownloadOutlined />} onClick={handleExport} size="middle" style={buttonStyle} >
                        下載人員備份資料
                    </Button>
                </Row>
                <Row>
                    <h3>還原資料</h3>
                </Row>
                <Row gutter={24} style={{ marginBottom: '20px', textAlign: 'left' }}>
                    <Upload
                        beforeUpload={() => false} // 阻止自动上传
                        onChange={handleFileChange} // 直接触发文件选择后的处理逻辑
                        showUploadList={false}
                        accept=".json"
                    >
                        <Button type="primary" icon={<UploadOutlined />} size="middle" style={buttonStyle} disabled={loading}>
                            選擇備份人員資料
                        </Button>
                    </Upload>
                </Row>
                <Row>
                    <h3>Realtime Database 使用量</h3>
                </Row>
                <Row>
                    <Col span={12}>
                    {dataUsage.length > 0 && (
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={dataUsage}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="size" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    )}  
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default Setting;



