// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// 引入不同的頁面元件
import Home from './pages/Home';
import Login from './pages/Login';
import PersonnelRecord from './pages/PersonnelRecord';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute'; // 引入ProtectedRoute元件
import PeopleManagement from './pages/PeopleManagement';
import PunchRecord from './pages/PunchRecord';
import InstantPunchRecord from './pages/InstantPunchRecord';
import Setting from './pages/Setting';
import WorkstudyStudentManagement from './pages/WorkstudyStudentManagement';

function App() {
  return (
    <Router>
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/WorkstudyStudentManagement" element={<WorkstudyStudentManagement />} />
            {/* 使用ProtectedRoute來保護PersonnelRecord路徑 */}
            <Route
              path="/PersonnelRecord"
              component={PersonnelRecord}
              element={
                <ProtectedRoute>
                  <PersonnelRecord />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PeopleManagement"
              component={PeopleManagement}
              element={
                <ProtectedRoute>
                  <PeopleManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PunchRecord"
              component={PunchRecord}
              element={
                <ProtectedRoute>
                  <PunchRecord />
                </ProtectedRoute>
              }
            />
            <Route
              path="/InstantPunchRecord"
              component={InstantPunchRecord}
              element={
                <ProtectedRoute>
                  <InstantPunchRecord />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Setting"
              component={Setting}
              element={
                <ProtectedRoute>
                  <Setting />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <Footer /> {/* 在頁面底部展示 Footer */}
      </div>
    </Router>
  );
}

export default App;

