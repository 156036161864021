// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, getDocs, doc, setDoc  } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyB4be0BLLE4wKLEpeh1kyGZrvnM7GGfxto",
    authDomain: "web-cramschool.firebaseapp.com",
    projectId: "web-cramschool",
    storageBucket: "web-cramschool.appspot.com",
    messagingSenderId: "669758110505",
    appId: "1:669758110505:web:b99e7eaa5633da21b5d538"
  };

// 初始化 Firebase
const app = initializeApp(firebaseConfig);
// 初始化 Firestore
const db = getFirestore(app);

// 獲取 Firebase Auth 實例
export const auth = getAuth(app);
export { signInWithEmailAndPassword };
export { db };
export { collection, getDocs, doc, setDoc };