import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const Captcha = ({ onCaptchaChange }) => {
  const [captcha, setCaptcha] = useState('');
  const canvasRef = useRef(null);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < 4; i++) { // 生成4位數驗證碼
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    setCaptcha(result);
    drawCaptcha(result);
    onCaptchaChange(result);  // 將生成的驗證碼回傳給父元件
  };

  const drawCaptcha = (text) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // 背景顏色
    ctx.fillStyle = '#f3f3f3';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 繪製干擾線
    for (let i = 0; i < 5; i++) {
      ctx.strokeStyle = getRandomColor();
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.stroke();
    }

    // 繪製驗證碼字符
    for (let i = 0; i < text.length; i++) {
      const char = text.charAt(i);
      ctx.font = `${24 + Math.random() * 8}px Arial`; // 隨機字體大小
      ctx.fillStyle = getRandomColor(); // 隨機字體顏色
      ctx.save();
      ctx.translate(25 * (i + 1), 25); // 調整字符位置，確保4個字符都能顯示
      ctx.rotate((Math.random() - 0.5) * 0.4); // 隨機旋轉
      ctx.fillText(char, 0, 0);
      ctx.restore();
    }

    // 繪製干擾點
    for (let i = 0; i < 30; i++) {
      ctx.fillStyle = getRandomColor();
      ctx.beginPath();
      ctx.arc(Math.random() * canvas.width, Math.random() * canvas.height, 1.5, 0, Math.PI * 2);
      ctx.fill();
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div>
      <canvas ref={canvasRef} width="150" height="50" style={{ border: '1px solid #000' }}></canvas>
      <Button onClick={generateCaptcha} type="link" icon={<ReloadOutlined />}>
        重新整理
      </Button>
    </div>
  );
};

export default Captcha;
