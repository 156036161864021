import React, { useState } from 'react';
import { Layout, Menu, Row, Col, Drawer, Button } from 'antd';
import { UserOutlined, ClockCircleOutlined, UploadOutlined, LogoutOutlined, HomeOutlined, MenuOutlined, SettingOutlined } from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../utils/firebase';

const { Header } = Layout;

const pathNames = {
    '/PersonnelRecord': '首頁',
    '/PeopleManagement': '人員管理',
    '/PunchRecord': '打卡記錄',
    '/InstantPunchRecord': '即時打卡紀錄',
    '/Setting': '設置',
};

const HeaderComponent = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = pathNames[location.pathname] || '未知路徑';

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleLogout = async () => {
        try {
            await auth.signOut(); // 先執行登出操作
            navigate('/login', { replace: true }); // 登出成功後再跳轉到登錄頁面
        } catch (error) {
            console.error('登出失敗：', error);
        }
    };

    return (
        <Header style={{ backgroundColor: '#bfbfbf', padding: '0 20px' }}>
            <Row justify="space-between" align="middle">
                <Col>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            className="menu-button"
                            type="text"
                            icon={<MenuOutlined />}
                            onClick={showDrawer}
                            style={{ marginRight: '16px', fontSize: '18px', color: '#000' }}
                        />
                        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>打卡系統</span>
                    </div>
                </Col>
                <Col>
                    <Button onClick={handleLogout} icon={<LogoutOutlined />} style={{ fontSize: '18px', color: '#000' }}>
                        登出
                    </Button>
                </Col>
            </Row>
            <Row justify="start" style={{ marginTop: '-7px', marginBottom: '10px' }}>  {/* 將 marginTop 設為負值以向上移動 */}
                <Col>
                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
                        {currentPath}
                    </div>
                </Col>
            </Row>
            <Drawer
                title="選單"
                placement="left"
                closable={false}
                onClose={onClose}
                open={visible}
            >
                <Menu mode="vertical">
                    <Menu.Item key="1" icon={<HomeOutlined />} onClick={onClose}>
                        <Link to="/PersonnelRecord">首頁</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<UserOutlined />} onClick={onClose}>
                        <Link to="/PeopleManagement">人員管理</Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<ClockCircleOutlined />} onClick={onClose}>
                        <Link to="/PunchRecord">打卡記錄</Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<UploadOutlined />} onClick={onClose}>
                        <Link to="/InstantPunchRecord">即時打卡紀錄</Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<SettingOutlined />} onClick={onClose}>
                        <Link to="/Setting">設置</Link>
                    </Menu.Item>
                </Menu>
            </Drawer>
        </Header>
    );
};

export default HeaderComponent;
