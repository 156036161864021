import React, { useEffect, useState } from 'react';
import { Layout, Table } from 'antd';
import HeaderComponent from '../components/HeaderComponent';
import { getDatabase, ref, onValue } from 'firebase/database';
import moment from 'moment';

const { Content } = Layout;

const InstantPunchRecord = () => {
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const db = getDatabase();
        const attendanceRef = ref(db, 'attendance');

        const fetchData = () => {
            onValue(attendanceRef, (snapshot) => {
                const today = moment().format('YYYY-MM-DD');
                const records = [];
                snapshot.forEach((dateSnapshot) => {
                    if (dateSnapshot.key === today) { // 只保留当天的记录
                        dateSnapshot.forEach((recordSnapshot) => {
                            records.push({ key: recordSnapshot.key, ...recordSnapshot.val(), date: dateSnapshot.key });
                        });
                    }
                });
                // 按照打卡时间排序，最晚打卡的放最前面
                records.sort((a, b) => {
                    // const dateA = moment(`${a.date} ${a.check_in_time}`, 'YYYY-MM-DD HH:mm:ss');
                    // const dateB = moment(`${b.date} ${b.check_in_time}`, 'YYYY-MM-DD HH:mm:ss');
                    const dateA = moment(a.check_in_time, 'YYYY-MM-DD HH:mm:ss');
                    const dateB = moment(b.check_in_time, 'YYYY-MM-DD HH:mm:ss');
                    // Debug 输出以检查解析后的时间
                    console.log('DateA:', dateA.format('YYYY-MM-DD HH:mm:ss'));
                    console.log('DateB:', dateB.format('YYYY-MM-DD HH:mm:ss'));

                    return dateB - dateA;
                });

                setFilteredData(records);
            });
        };

        fetchData();

        // 每隔60秒刷新一次数据
        const intervalId = setInterval(fetchData, 1500);

        // 清理定时器
        return () => clearInterval(intervalId);
    }, []);

    const columns = [
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '卡片ID',
            dataIndex: 'card_id',
            key: 'card_id',
        },
        {
            title: '學校',
            dataIndex: 'school',
            key: 'school',
        },
        {
            title: '打卡時間',
            dataIndex: 'check_in_time',
            key: 'check_in_time',
        },
    ];

    return (
        <Layout>
            <HeaderComponent />
            <Content style={{ padding: '20px', paddingTop: '50px' }}>
                <Table columns={columns} dataSource={filteredData} />
            </Content>
        </Layout>
    );
};

export default InstantPunchRecord;
