import React from 'react';
import { Modal, Button } from 'antd';

const DeleteConfirmation = ({ open, onConfirm, onCancel, studentName }) => {
    return (
        <Modal
            title="確認刪除"
            open={open}  // 使用 open 而不是 visible
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    取消
                </Button>,
                <Button key="confirm" type="primary" danger onClick={onConfirm}>
                    確認刪除
                </Button>,
            ]}
        >
            <p>你確定要刪除 {studentName} 的資料嗎？</p>
        </Modal>
    );
};

export default DeleteConfirmation;
