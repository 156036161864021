    import React, { useEffect, useState } from 'react';
    import { Layout, Row, Col, Table, Input, DatePicker, Button } from 'antd';
    import HeaderComponent from '../components/HeaderComponent';
    import { getDatabase, ref, onValue } from 'firebase/database';
    import moment from 'moment';
    import * as XLSX from 'xlsx';

    const { Content } = Layout;
    const { RangePicker } = DatePicker;

    const PunchRecord = () => {
        const [data, setData] = useState([]);
        const [groupedData, setGroupedData] = useState([]);
        const [filteredData, setFilteredData] = useState([]);
        const [nameSearch, setNameSearch] = useState('');
        const [dateRange, setDateRange] = useState([null, null]);

        useEffect(() => {
            const db = getDatabase();
            const attendanceRef = ref(db, 'attendance');

            const fetchData = () => {
                onValue(attendanceRef, (snapshot) => {
                    const records = [];
                    snapshot.forEach((dateSnapshot) => {
                        dateSnapshot.forEach((recordSnapshot) => {
                            records.push({ 
                                key: recordSnapshot.key, 
                                ...recordSnapshot.val(), 
                                date: moment(dateSnapshot.key).format('YYYY-MM-DD') // 確保日期格式一致
                            });
                        });
                    });
                    // 按照打卡時間排序，最晚打卡的放最前面
                    records.sort((a, b) => {
                        const dateA = moment(a.check_in_time, 'YYYY-MM-DD HH:mm:ss');
                        const dateB = moment(b.check_in_time, 'YYYY-MM-DD HH:mm:ss');
                        return dateB - dateA;
                    });

                    // 按日期分組
                    const grouped = groupBy(records, 'date');
                    setData(grouped);
                    setFilteredData(grouped);
                });
            };

            fetchData();
        }, []);

        // 將資料按日期分組的函數
        const groupBy = (array, key) => {
            return array.reduce((result, currentValue) => {
                console.log('result',result);
                console.log('currentValue',currentValue);
                (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
                return result;
            }, {});
        };

        const handleSearch = () => {
            let filtered = data;
        
            if (nameSearch) {
                filtered = Object.keys(data).reduce((result, date) => {
                    const filteredRecords = data[date].filter(record => record.name === nameSearch);
                    if (filteredRecords.length) {
                        result[date] = filteredRecords;
                    }
                    return result;
                }, {});
            }
        
            if (dateRange[0] && dateRange[1]) {
                const [startDate, endDate] = dateRange.map(date => date.format('YYYY-MM-DD'));
                filtered = Object.keys(filtered).reduce((result, date) => {
                    if (date >= startDate && date <= endDate) {
                        result[date] = filtered[date];
                    }
                    return result;
                }, {});
            }
        
            setFilteredData(filtered);
        };

        const cancelhandleSearch = () => {
            setNameSearch('');
            setDateRange([null, null]);
            setFilteredData(data);
        };

        const handleExport = () => {
            const exportData = [];
            Object.keys(filteredData).forEach(date => {
                filteredData[date].forEach(record => {
                    exportData.push({
                        '日期': record.date,
                        '姓名': record.name,
                        '卡片ID': record.card_id,
                        '學校': record.school,
                        '打卡時間': record.check_in_time
                    });
                });
            });

            const ws = XLSX.utils.json_to_sheet(exportData, { header: ['日期', '姓名', '卡片ID', '學校', '打卡時間'] });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "打卡紀錄");
            XLSX.writeFile(wb, "打卡紀錄.xlsx");
        };

        const columns = [
            {
                title: '日期',
                dataIndex: 'date',
                key: 'date',
            },
        ];

        const expandedRowRender = (date) => {
            const detailColumns = [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '卡片ID',
                    dataIndex: 'card_id',
                    key: 'card_id',
                },
                {
                    title: '學校',
                    dataIndex: 'school',
                    key: 'school',
                },
                {
                    title: '打卡時間',
                    dataIndex: 'check_in_time',
                    key: 'check_in_time',
                },
            ];
            return <Table columns={detailColumns} dataSource={filteredData[date]} pagination={false} />;
        };

        const dataForTable = Object.keys(filteredData).map((date) => ({
            key: date,
            date: date,
        }));

        return (
            <Layout>
                <HeaderComponent />
                <Content style={{ padding: '20px', paddingTop: '50px' }}>
                    <Row gutter={24} style={{ marginBottom: '20px', textAlign: 'left' }}>
                        {/* <Col span={4}>
                            <Input
                                placeholder="輸入姓名搜尋"
                                value={nameSearch}
                                onChange={(e) => setNameSearch(e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <RangePicker
                                format="YYYY-MM-DD"
                                value={dateRange}
                                onChange={(dates) => setDateRange(dates)}
                            />
                        </Col>
                        <Col span={7}>
                            <Button type="primary" onClick={handleSearch}>搜尋</Button>
                        </Col>
                        <Col span={4}>
                            <Button 
                                type="primary" 
                                style={{
                                    backgroundColor: '#ffffff',
                                    color: '#1890ff',
                                    borderColor: '#1890ff'
                                }}
                                onClick={cancelhandleSearch}
                            >
                                取消搜尋
                            </Button>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button 
                                type="primary" 
                                style={{
                                    backgroundColor: '#006400',
                                    color: '#ffffff',
                                    borderColor: '#006400',
                                }}
                                onClick={handleExport}
                            >
                                Excel
                            </Button>
                        </Col> */}
                        <Col span={24}>
                            <Input
                                    placeholder="輸入姓名搜尋"
                                    value={nameSearch}
                                    onChange={(e) => setNameSearch(e.target.value)}
                                    style={{ width: '250px' , marginRight: '20px' }} // 限制UI顯示的寬度
                            />
                            <RangePicker
                                format="YYYY-MM-DD"
                                value={dateRange}
                                onChange={(dates) => setDateRange(dates)}
                                style={{ width: '250px' , marginRight: '20px' }}
                            />
                            <Button type="primary" onClick={handleSearch} style={{ marginRight: '20px' }}>搜尋</Button>
                            <Button 
                                type="primary" 
                                style={{
                                    backgroundColor: '#ffffff',
                                    color: '#1890ff',
                                    borderColor: '#1890ff',
                                }}
                                onClick={cancelhandleSearch}
                            >
                                取消搜尋
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Button 
                            type="primary" 
                            style={{
                                backgroundColor: '#006400',
                                color: '#ffffff',
                                borderColor: '#006400',
                                marginBottom: '20px'
                            }}
                            onClick={handleExport}
                        >
                            Excel
                        </Button>
                    </Row>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record.date),
                        }}
                        dataSource={dataForTable}
                    />
                </Content>
            </Layout>
        );
    };

    export default PunchRecord;




    // import React, { useEffect, useState } from 'react';
    // import { Layout, Row, Col, Table, Input, DatePicker, Button } from 'antd';
    // import HeaderComponent from '../components/HeaderComponent';
    // import { getDatabase, ref, onValue } from 'firebase/database';
    // import moment from 'moment';
    // import * as XLSX from 'xlsx';

    // const { Content } = Layout;
    // const { RangePicker } = DatePicker;

    // const PunchRecord = () => {
    //     const [data, setData] = useState([]);
    //     const [filteredData, setFilteredData] = useState([]);
    //     const [nameSearch, setNameSearch] = useState('');
    //     const [dateRange, setDateRange] = useState([null, null]);

    //     useEffect(() => {
    //         const db = getDatabase();
    //         const attendanceRef = ref(db, 'attendance');

    //         const fetchData = () => {
    //             onValue(attendanceRef, (snapshot) => {
    //                 const records = [];
    //                 snapshot.forEach((dateSnapshot) => {
    //                     dateSnapshot.forEach((recordSnapshot) => {
    //                         records.push({ 
    //                             key: recordSnapshot.key, 
    //                             ...recordSnapshot.val(), 
    //                             date: moment(dateSnapshot.key).format('YYYY-MM-DD') // 確保日期格式一致
    //                         });
    //                     });
    //                 });
    //                 // 按照打卡時間排序，最晚打卡的放最前面
    //                 records.sort((a, b) => {
    //                     // const dateA = moment(a.date + ' ' + a.check_in_time, 'YYYY-MM-DD HH:mm:ss');
    //                     // const dateB = moment(b.date + ' ' + b.check_in_time, 'YYYY-MM-DD HH:mm:ss');
    //                     const dateA = moment(a.check_in_time, 'YYYY-MM-DD HH:mm:ss');
    //                     const dateB = moment(b.check_in_time, 'YYYY-MM-DD HH:mm:ss');
    //                     return dateB - dateA;
    //                 });
    //                 setData(records);
    //                 setFilteredData(records); // 初始化時顯示所有數據
    //             });
    //         };

    //         fetchData();
    //     }, []);

    //     const handleExport = () => {
    //         // 只选择要导出的字段，并添加中文标题
    //         const exportData = filteredData.map(record => ({
    //             '日期': record.date,
    //             '姓名': record.name,
    //             '卡片ID': record.card_id,
    //             '學校': record.school,
    //             '打卡時間': record.check_in_time
    //         }));

    //         // 创建 Excel 工作表
    //         const ws = XLSX.utils.json_to_sheet(exportData, { header: ['日期', '姓名', '卡片ID', '學校', '打卡時間'] });

    //         // 创建工作簿并将工作表添加到工作簿中
    //         const wb = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(wb, ws, "打卡紀錄");

    //         // 导出 Excel 文件
    //         XLSX.writeFile(wb, "打卡紀錄.xlsx");
    //     };


    //     const handleSearch = () => {
    //         let filtered = data;
        
    //         if (nameSearch) {
    //             filtered = filtered.filter(record => record.name === nameSearch);
    //         }
        
    //         if (dateRange[0] && dateRange[1]) {
    //             const [startDate, endDate] = dateRange.map(date => date.format('YYYY-MM-DD'));
    //             filtered = filtered.filter(record => {
    //                 const recordDate = record.date;
    //                 return recordDate >= startDate && recordDate <= endDate;
    //             });
    //         }
        
    //         setFilteredData(filtered);
    //     };
        
    //     const cancelhandleSearch = () => {
    //         // 清空搜尋的姓名和日期範圍
    //         setNameSearch('');
    //         setDateRange([null, null]); // 清除日期範圍

    //         // 重置表格數據為所有資料
    //         setFilteredData(data);
    //     };

    //     const columns = [
    //         {
    //             title: '日期',
    //             dataIndex: 'date',
    //             key: 'date',
    //         },
    //         {
    //             title: '姓名',
    //             dataIndex: 'name',
    //             key: 'name',
    //         },
    //         {
    //             title: '卡片ID',
    //             dataIndex: 'card_id',
    //             key: 'card_id',
    //         },
    //         {
    //             title: '學校',
    //             dataIndex: 'school',
    //             key: 'school',
    //         },
    //         {
    //             title: '打卡時間',
    //             dataIndex: 'check_in_time',
    //             key: 'check_in_time',
    //         },
    //     ];

    //     return (
    //         <Layout>
    //             <HeaderComponent />
    //             <Content style={{ padding: '20px', paddingTop: '50px'   }}>
    //                 <Row gutter={24} style={{ marginBottom: '20px', textAlign: 'left' }}>
    //                     <Col span={4}>
    //                         <Input
    //                             placeholder="輸入姓名搜尋"
    //                             value={nameSearch}
    //                             onChange={(e) => setNameSearch(e.target.value)}
    //                         />
    //                     </Col>
    //                     <Col span={6}>
    //                         <RangePicker
    //                             format="YYYY-MM-DD"
    //                             value={dateRange} // 綁定日期範圍到 RangePicker 的值
    //                             onChange={(dates) => setDateRange(dates)}
    //                         />
    //                     </Col>
    //                     <Col span={3}>
    //                         <Button type="primary" onClick={handleSearch}>搜尋</Button>
    //                     </Col>
    //                     <Col span={4}>
    //                         <Button 
    //                             type="primary" 
    //                             style={{
    //                                 backgroundColor: '#ffffff',
    //                                 color: '#1890ff',
    //                                 borderColor: '#1890ff'
    //                             }}
    //                             onClick={cancelhandleSearch}
    //                         >
    //                             取消搜尋
    //                         </Button>
    //                     </Col>
    //                     <Col span={6} style={{ textAlign: 'right' }}>
    //                         <Button 
    //                             type="primary" 
    //                             style={{
    //                                 backgroundColor: '#006400',  // 设置按钮背景为深绿色
    //                                 color: '#ffffff',  // 设置文字颜色为白色
    //                                 borderColor: '#006400',  // 设置边框颜色为深绿色（与背景相同） 
    //                             }}
    //                             onClick={handleExport}
    //                         >
    //                             Excel
    //                         </Button>
    //                     </Col>
    //                 </Row>
    //                 <Table columns={columns} dataSource={filteredData} />
    //             </Content>
    //         </Layout>
    //     );
    // };

    // export default PunchRecord;


