//WorkstudyStudentManagement
import React from 'react';

function WorkstudyStudentManagement() {
    return (
        <div>
        <h1>WorkstudyStudentManagement</h1>
        </div>
    );
}

export default WorkstudyStudentManagement;
