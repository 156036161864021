import React, { useEffect, useState } from 'react';
import { Layout, Table, Row, Col, Button, Modal } from 'antd';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore'; 
import { db } from '../utils/firebase'; 
import HeaderComponent from '../components/HeaderComponent';
import CreatStudentForm from '../components/CreatStudentForm';
import UpdateStudentForm from '../components/UpdateStudentForm';
import Search from '../components/Search'; // 导入你的SearchComponent
import DeleteConfirmation from '../components/DeleteConfirmation';


const { Content } = Layout;

const PeopleManagement = () => {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]); // 用于存储过滤后的数据
    const [modalVisible, setModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editingStudent, setEditingStudent] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false); // 控制刪除確認對話框的顯示
    const [deletingStudent, setDeletingStudent] = useState(null); // 當前要刪除的學生

    useEffect(() => {
        const fetchStudents = async () => {
            const studentsCollection = collection(db, 'students');
            const studentsSnapshot = await getDocs(studentsCollection);
            const studentsList = studentsSnapshot.docs.map(doc => ({
                key: doc.id, // 使用 document ID 作為唯一鍵
                ...doc.data(),
            }));
    
            // 对学生数据按照 last_edited_time 进行排序
            studentsList.sort((a, b) => {
                if (a.last_edited_time && b.last_edited_time) {
                    return new Date(b.last_edited_time) - new Date(a.last_edited_time);
                }
                return 0;
            });
    
            setStudents(studentsList);
            setFilteredStudents(studentsList); // 初始时设置为完整数据
        };
    
        fetchStudents();
    }, []);

    const handleOpenModal = () => {
        setModalVisible(true);
    };
    
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const handleOpenEditModal = (student) => {
        setEditingStudent(student);
        setEditModalVisible(true);
    };

    const handleCloseEditModal = () => {
        setEditModalVisible(false);
        setEditingStudent(null);
    };

    const handleOpenDeleteModal = (student) => {
        setDeletingStudent(student);
        setDeleteModalVisible(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalVisible(false);
        setDeletingStudent(null);
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, 'students', deletingStudent.key));
            setStudents(students.filter(student => student.key !== deletingStudent.key));
            setFilteredStudents(filteredStudents.filter(student => student.key !== deletingStudent.key)); // 更新过滤后的数据
            handleCloseDeleteModal();
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };
    

    const handleSearch = (value) => {
        const filtered = students.filter(student => 
            student.name.includes(value)
        );
        setFilteredStudents(filtered);
    };
    const cancelhandleSearch = () => {

    }
    const addStudentToTable = (student) => {
        setStudents(prevStudents => {
            const updatedStudents = [...prevStudents, student];
    
            // 对 updatedStudents 按照 last_edited_time 进行排序
            updatedStudents.sort((a, b) => {
                if (a.last_edited_time && b.last_edited_time) {
                    return new Date(b.last_edited_time) - new Date(a.last_edited_time);
                }
                return 0;
            });
            setFilteredStudents(updatedStudents); // 更新过滤后的数据
            return updatedStudents;
        });
    };

    const updateStudentInTable = (updatedStudent) => {
        setStudents(prevStudents =>
            prevStudents.map(student => 
                student.key === updatedStudent.key ? updatedStudent : student
            )
        );
        setFilteredStudents(prevStudents =>
            prevStudents.map(student => 
                student.key === updatedStudent.key ? updatedStudent : student
            )
        );
    };

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '性別',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: '出生日期',
            dataIndex: 'birthdate',
            key: 'birthdate',
        },
        {
            title: '電話號碼',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '卡片ID',
            dataIndex: 'card_id',
            key: 'card_id',
        },
        {
            title: '學校',
            dataIndex: 'school_name',
            key: 'school_name',
        },
        {
            title: '學習階段',
            dataIndex: 'school_level',
            key: 'school_level',
        },
        {
            title: '年級',
            dataIndex: 'school_grade',
            key: 'school_grade',
        },
        {
            title: '科目',
            dataIndex: 'subjects',
            key: 'subjects',
            render: (subjects) => (
                <>
                    {subjects && subjects.map((subject, index) => (
                        <span key={index}>
                            {subject}
                            {index < subjects.length - 1 && <br />}  {/* 每个科目后加换行 */}
                        </span>
                    ))}
                </>
            ),
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Button 
                        type="link" 
                        onClick={() => handleOpenEditModal(record)}
                        style={{ 
                            fontWeight: 'bold', 
                            border: '1px solid #1890ff',  // 设置边框颜色和样式
                            padding: '2px 8px',           // 设置内边距，使按钮看起来更像带边框的按钮
                            borderRadius: '4px',           // 设置边框圆角
                            marginRight: '8px'            // 设置右边距，增加与后一个按钮的间距
                        }}
                    >
                        修改
                    </Button>
                    <Button 
                        type="link" 
                        danger 
                        onClick={() => handleOpenDeleteModal(record)}  // 修改此行，打开删除确认对话框
                        style={{ 
                            fontWeight: 'bold', 
                            border: '1px solid #ff4d4f',  // 设置边框颜色和样式
                            padding: '2px 8px',           // 设置内边距，使按钮看起来更像带边框的按钮
                            borderRadius: '4px'           // 设置边框圆角}}  // 设置字体为粗体
                        }}
                    >
                        刪除
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <Layout>
            <HeaderComponent />
            <Content style={{ padding: '20px', paddingTop: '50px'  }}>
                <Row gutter={24} style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <Col span={6}>
                        <Search onSearch={handleSearch} />  {/* 使用自定义的SearchComponent */}
                    </Col>
                    {/* <Col span={2}>
                            <Button 
                                type="primary" 
                                style={{
                                    backgroundColor: '#ffffff',
                                    color: '#1890ff',
                                    borderColor: '#1890ff'
                                }}
                                onClick={cancelhandleSearch}
                            >
                                取消搜尋
                            </Button>
                    </Col> */}
                    <Col span={18} style={{ textAlign: 'right' }}>
                        <Button onClick={handleOpenModal} type='primary' >
                            新增人員
                        </Button>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={24}>
                        <Table columns={columns} dataSource={filteredStudents} />
                    </Col>
                </Row>
                <Modal
                    title="新增學生"
                    open={modalVisible}
                    footer={null}
                    onCancel={handleCloseModal}
                >
                    <CreatStudentForm onSuccess={handleCloseModal} addStudentToTable={addStudentToTable} />
                </Modal>
                <Modal
                    title="更新學生"
                    open={editModalVisible}
                    footer={null}
                    onCancel={handleCloseEditModal}
                >
                <UpdateStudentForm 
                    student={editingStudent} 
                    onSuccess={(updatedStudent) => {
                        handleCloseEditModal();
                        updateStudentInTable(updatedStudent);
                    }} 
                />
                </Modal>
                <DeleteConfirmation
                    open={deleteModalVisible}  // 使用 open 而不是 visible
                    onConfirm={handleDelete}
                    onCancel={handleCloseDeleteModal}
                    studentName={deletingStudent?.name}
                />
            </Content>
        </Layout>
    );
};

export default PeopleManagement;
