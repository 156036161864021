import React from 'react';
import { Input } from 'antd';

const { Search } = Input;

const SearchComponent = ({ onSearch }) => {
    const handleSearch = (value) => {
        onSearch(value); // 将输入的搜索条件传递给父组件
    };

    return (
        <Search 
            placeholder="輸入姓名搜尋" 
            allowClear 
            enterButton="搜尋" 
            size="middle" 
            onSearch={handleSearch} 
            style={{ width: '300px'}}  // 设置宽度和高度
        />
    );
};

export default SearchComponent;
