import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, Select, message, Checkbox } from 'antd';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../utils/firebase';

const { Option } = Select;

const subjectsOptions = [
    { label: '數學', value: '數學' },
    { label: '英文', value: '英文' },
    { label: '國文', value: '國文' },
    { label: '物理', value: '物理' },
    { label: '化學', value: '化學' },
    { label: '社會', value: '社會' },
    // 添加更多的科目选项
];

const CreatStudentForm = ({ onSuccess, addStudentToTable  }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();  // 使用 useForm 来获取表单实例


    const handleFinish = async (values) => {
        setLoading(true);
    
        try {
            const studentsCollection = collection(db, 'students');
    
            // 检查是否已存在相同的 card_id（忽略为空的字段）
            if (values.card_id) {
                const q = query(studentsCollection, where('card_id', '==', values.card_id));
                const querySnapshot = await getDocs(q);
    
                if (!querySnapshot.empty) {
                    message.error('已存在相同的卡片ID，请确认后再试');
                    setLoading(false);
                    return; // 中止新增操作
                }
            }
    
            const currentTime = new Date();  // 定义 currentTime 变量
    
            // 确保每个字段都有有效的值，空字段允许设置为空字符串
            const studentData = {
                name: values.name || '',
                gender: values.gender || '',
                phone: values.phone || '',
                card_id: values.card_id || '',
                school_name: values.school_name || '',
                school_level: values.school_level || '',
                school_grade: values.school_grade || '',
                birthdate: values.birthdate ? values.birthdate.format('YYYY-MM-DD') : '',
                subjects: values.subjects || [],  // 保存选择的科目
                last_edited_time: currentTime.toISOString(), // 保存为 ISO 格式字符串
            };
    
            // 新增資料到 Firestore
            const docRef = await addDoc(studentsCollection, studentData);
    
            // 更新表格數據
            addStudentToTable({ ...studentData, key: docRef.id });
    
            message.success('學生資料已成功新增');
            form.resetFields();
            onSuccess(); // 新增成功後調用回調函數
        } catch (error) {
            console.error("Error adding document: ", error);
            message.error('新增失敗，請稍後再試');
        } finally {
            setLoading(false);
        }
    };
    


    return (
        <Form layout="vertical" form={form} onFinish={handleFinish}>
            <Form.Item
                name="name"
                label="學生姓名"
                rules={[{ required: true, message: '請輸入學生姓名' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="gender"
                label="性別"
            >
                <Select>
                    <Option value="男">男</Option>
                    <Option value="女">女</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="phone"
                label="電話號碼"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="card_id"
                label="卡片ID"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="school_name"
                label="學校名稱"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="school_level"
                label="學習階段"
                rules={[{ required: true }]}
            >
                <Select>
                    <Option value="幼稚園">幼稚園</Option>
                    <Option value="國小">國小</Option>
                    <Option value="國中">國中</Option>
                    <Option value="高中">高中</Option>
                    <Option value="大學">大學</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="school_grade"
                label="年級"
            >
                <Select>
                    <Option value="幼幼班">幼幼班</Option>
                    <Option value="小">小</Option>
                    <Option value="中">中</Option>
                    <Option value="大">大</Option>
                    <Option value="一">一</Option>
                    <Option value="二">二</Option>
                    <Option value="三">三</Option>
                    <Option value="四">四</Option>
                    <Option value="五">五</Option>
                    <Option value="六">六</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="birthdate"
                label="出生日期"
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name="subjects"
                label="科目"
                rules={[{ required: true, message: '請選擇至少一個科目' }]}
            >
                <Checkbox.Group options={subjectsOptions} style={{ display: 'block' }} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    新增學生
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreatStudentForm;
