import React, { useEffect } from 'react';
import { Form, Input, Button, DatePicker, Select, Checkbox, message } from 'antd';
import moment from 'moment';
import { doc, updateDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { db } from '../utils/firebase';

const { Option } = Select;

const subjectsOptions = [
    { label: '數學', value: '數學' },
    { label: '英文', value: '英文' },
    { label: '國文', value: '國文' },
    { label: '物理', value: '物理' },
    { label: '化學', value: '化學' },
    { label: '社會', value: '社會' },
    // 添加更多的科目选项
];

const UpdateStudentForm = ({ student, onSuccess }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (student) {
            form.setFieldsValue({
                ...student,
                birthdate: student.birthdate ? moment(student.birthdate) : null,
                subjects: student.subjects || [],  // 确保 subjects 正确加载
            });
        }
    }, [student, form]);

    const handleFinish = async (values) => {
        try {
            const studentsCollection = collection(db, 'students');
            
            // 當 card_id 不為空時，檢查是否已經存在相同的 card_id
            if (values.card_id) {
                const q = query(studentsCollection, where('card_id', '==', values.card_id));
                const querySnapshot = await getDocs(q);
            
                // 如果查詢結果中存在文檔，且該文檔不是當前正在編輯的學生，則拒絕更新
                if (!querySnapshot.empty && querySnapshot.docs.some(doc => doc.id !== student.key)) {
                    message.error('已存在相同的卡片ID，請確認後再試');
                    return; // 中止更新操作
                }
            }
    
            const studentDocRef = doc(db, 'students', student.key);
            const updatedStudentData = {
                ...values,
                birthdate: values.birthdate ? values.birthdate.format('YYYY-MM-DD') : '',
            };
    
            await updateDoc(studentDocRef, updatedStudentData);
            
            // 调用父组件的 onSuccess 回调，并传递更新后的学生数据
            onSuccess({ ...updatedStudentData, key: student.key });
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };
    

    return (
        <Form layout="vertical" form={form} onFinish={handleFinish}>
            <Form.Item
                name="name"
                label="學生姓名"
                rules={[{ required: true, message: '請輸入學生姓名' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="gender"
                label="性別"
            >
                <Select>
                    <Option value="男">男</Option>
                    <Option value="女">女</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="phone"
                label="電話號碼"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="card_id"
                label="卡片ID"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="school_name"
                label="學校名稱"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="school_level"
                label="學習階段"
                rules={[{ required: true }]}
            >
                <Select>
                    <Option value="幼稚園">幼稚園</Option>
                    <Option value="國小">國小</Option>
                    <Option value="國中">國中</Option>
                    <Option value="高中">高中</Option>
                    <Option value="大學">大學</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="school_grade"
                label="年級"
            >
                <Select>
                <Option value="幼幼班">幼幼班</Option>
                    <Option value="小">小</Option>
                    <Option value="中">中</Option>
                    <Option value="大">大</Option>
                    <Option value="一">一</Option>
                    <Option value="二">二</Option>
                    <Option value="三">三</Option>
                    <Option value="四">四</Option>
                    <Option value="五">五</Option>
                    <Option value="六">六</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="birthdate"
                label="出生日期"
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name="subjects"
                label="科目"
                rules={[{ required: true, message: '請選擇至少一個科目' }]}
            >
                <Checkbox.Group options={subjectsOptions} style={{ display: 'block' }} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    更新資料
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateStudentForm;
