// src/components/ProtectedRoute.js
import React from 'react';
import { Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/UseAuth'; // 确保路径正确

const ProtectedRoute = ({ children }) => {
    const { user, loading } = useAuth(); // 获取当前登录的用户信息及加载状态

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" /> {/* 显示加载指示器 */}
            </div>
        );
    }

    if (!user) {
        // 如果没有登录，则重定向到 /login
        return <Navigate to="/login" />;
    }

    // 如果已经登录，则渲染子组件
    return children;
};

export default ProtectedRoute;
